<template>
<div class="artist-management">
 <div class="main">
   <el-breadcrumb separator="/">
     <el-breadcrumb-item>艺人管理</el-breadcrumb-item>
   </el-breadcrumb>
   <el-divider></el-divider>
   <div class="nav">
     <el-button type="primary" @click="newArtists" class="add-artist">+&nbsp;&nbsp;新增艺人</el-button>
     <el-input  placeholder="请输入艺人名" v-model="params.singer_name" @change="query">
       <i slot="prefix" class="el-input__icon el-icon-search"></i>
     </el-input>
   </div>
   <div class="table">
     <el-table
         fit
         :cell-style = "{textAlign:'center'}"
         :data="list"
         header-row-class-name="header-nav"
         style="width: 100%">
       <el-table-column
           type="index"
           :index="indexMethod"
           width="100"
           label="序号">
       </el-table-column>
       <el-table-column
           prop="singer_pic"
           label="艺人封面"
       >
           <template v-slot:default = "scope">
            <div style="display: flex;align-items: center;justify-content: center">
              <el-image  :src="scope.row.cover_pic" style="width: 50px;height: 50px;">
                <p slot="error">暂无图片</p>
              </el-image>
            </div>
           </template>
       </el-table-column>
       <el-table-column
           prop="singer_name"
           label="艺人名">
       </el-table-column>
       <el-table-column
           prop="singer_type"
           label="类型">
         <template v-slot:default = 'scope'>
           {{singerType[scope.row.singer_type]}}
         </template>
       </el-table-column>
       <el-table-column
            show-overflow-tooltip
           :formatter="screenFn"
           label="角色">
       </el-table-column>
       <el-table-column
           width="230px"
           prop="created_at"
           label="创建时间">
       </el-table-column>
       <el-table-column
           prop='status'
           label="状态">
         <template v-slot:default = 'scope'>
             {{STATUS[scope.row.status]}}
         </template>
       </el-table-column>
       <el-table-column
           prop="address"
           label="操作">
         <template v-slot:default = 'scope'>
           <el-button type="text" v-if="scope.row.status === 2" @click="editFn(scope.row)" >编辑</el-button>
           <el-button type="text"  v-if="scope.row.status === 1" @click="perfectArtists(scope.row.id)" >完善信息</el-button>
           <el-button type="text"  v-if="scope.row.status === 3" @click="isDeleteFn(scope.row)" >删除</el-button>
         </template>
       </el-table-column>
     </el-table>
     <div style="text-align: right;margin-top: 10px">
       <el-pagination
           background
           hide-on-single-page
           layout="prev, pager, next"
           @current-change="getSongHistory"
           :page-size="params.size"
           :total="total">
       </el-pagination>
     </div>
   </div>
 </div>
  <artist-popup ref="artistPopup" @determine = "getList" ></artist-popup>
  <hi-custom-popup :show.sync="show" @confirm="deleteFn" :but-show="true" >
    <div class="content">
      {{title}}
    </div>
  </hi-custom-popup>
</div>
</template>
<script>
import artistPopup from "@/views/administration/components/artistPopup";
import HiCustomPopup from "@/components/HiCustomPopup";
let STATUS = {1:'审核成功',2:'待审核',3:'审核失败'}
export default {
  name: "ArtistManagement",
  components:{
    artistPopup,
    HiCustomPopup
  },
  computed:{
    singerType(){
      return this.$store.state.singer ? this.$store.state.singer.singer_type : {}
    },
    singerRole(){
      return this.$store.state.singer ? this.$store.state.singer.singer_role : {}
    }
  },
  data(){
    return{
     params:{
       page:1,
       size:10,
       singer_name:''
     },
      total:0,
      list:[],
      STATUS,
      show:false,
      title:'',
      publicObj:{},
    }
  },
  created() {
  this.getList();
    },
  methods:{
    getSongHistory(val){
      this.params.page = val;
      this.getList()
    },
    newArtists(){
     this.$refs.artistPopup.initializeFn('新增艺人',0);
    },
    perfectArtists(id){
      this.$refs.artistPopup.initializeFn('完善艺人',2, id);
    },
    ediAartistFn(id){
      this.$refs.artistPopup.initializeFn('编辑艺人',1, id);
    },
    getList(){
      this.$post(`/api/issue/universal/singer/list`,this.params).then(r => {
        console.log(r,'rrr')
        if(r.code === 0){
          this.list = r.data.data;
          this.total = r.data.total;
        }
      })
    },
    screenFn(row){
      let r = row.singer_role.split(',')
      let i = []
       r.forEach(item => {
         i.push(this.singerRole[item])
       })
      return i.join(',')
    },
    editFn(row){
      this.ediAartistFn(row.id)
    },
    isDeleteFn(val){
      this.publicObj = val;
      this.show = true;
      this.title = `是否要删除艺人${val.singer_name}?`
    },
    deleteFn(){
       this.$post('/api/issue/universal/singer/delete',{id:this.publicObj.id}).then(r => {
         if(r.code === 0){
           this.show =false;
           this.getList();
         }
       })
    },
    indexMethod(index){
     return  ((this.params.page -1) * 10)  + ++index;
    },
    query(){
      this.params.page = 1;
      this.params.size = 10;
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
.nav{
  display: flex;
  justify-content: space-between;
  .add-artist{
    width: 122px;
    height: 40px;
    background: rgba(255,0,77,.1);
    border-radius: 2px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #ff004d;
    border: none;
  }
  .el-input{
    width: 290px;
    ::v-deep .el-input__inner{
      height: 40px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #c6cace;
      line-height: 14px;
    }
    ::v-deep .el-input__icon{
      line-height: 40px;
    }
  }
}
.table{
  margin-top: 20px;
}
.content{
  height: 130px;
  text-align: center;
  line-height: 130px;
}
</style>
<style lang="scss">
 .header-nav{
   .is-leaf{
     background: #f8f8f8 !important;
     font-size: 14px;
     font-family: PingFang SC, PingFang SC-Medium;
     font-weight: 500;
     text-align: LEFT;
     color: #555555;
     border: none!important;
     text-align: center;
   }
 }
</style>
